import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ToasterService } from '../../shared/utils/services/toastr.service';
import { MatDialog } from '@angular/material/dialog';
import { Banner } from '@webcoffee/interfaces';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../shared/ui/confirmation-dialog/confirmation-dialog.component';

export interface BannersState {
    banners: Banner[];
    selectedBanner: Partial<Banner> | null;
    selectedImage: File | null;
    selectedImageMobile: File | null;
}

export const initialBannersState: BannersState = {
    banners: [],
    selectedBanner: null,
    selectedImage: null,
    selectedImageMobile: null,
};

@Injectable()
export class BannersStore extends ComponentStore<BannersState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private dialog: MatDialog,
    ) {
        super(initialBannersState);
    }

    readonly getBanners = this.select((state: BannersState) => state.banners);
    readonly getSelectedBanner = this.select((state: BannersState) => state.selectedBanner);
    readonly getSelectedImage = this.select((state: BannersState) => state.selectedImage);
    readonly getSelectedImageMobile = this.select((state: BannersState) => state.selectedImageMobile);

    readonly getBannersEffect = this.effect(($) =>
        $.pipe(
            switchMap(() =>
                this.http.get<Banner[]>('/api/banners').pipe(
                    tap((banners) => this.patchState({ banners })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly createOrUpdateBannerEffect = this.effect(($) =>
        $.pipe(
            switchMap(() => {
                const banner = this.get().selectedBanner;
                const form = new FormData();

                if (!banner.id) {
                    const duplicate = this.get().banners.find((b) => b.name == banner.name);
                    if (duplicate) {
                        this.toastr.error('Nume duplicat');
                        return EMPTY;
                    }
                }

                form.append('images', this.get().selectedImage || new Blob());
                form.append('images', this.get().selectedImageMobile || new Blob());
                form.append('data', JSON.stringify({ ...banner, id: undefined }));

                if (banner.id) {
                    return this.http.patch(`/api/banners/${banner.id}`, form).pipe(
                        tap(() => {
                            this.patchState({ selectedBanner: null, selectedImage: null, selectedImageMobile: null });
                            this.getBannersEffect();
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                } else {
                    return this.http.post('/api/banners', form).pipe(
                        tap(() => {
                            this.patchState({ selectedBanner: null, selectedImage: null, selectedImageMobile: null });
                            this.getBannersEffect();
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
            }),
        ),
    );

    readonly deleteBannerEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) => {
                return this.dialog
                    .open(ConfirmationDialogComponent, {
                        data: {
                            message: 'Esti sigur ca vrei sa stergi acest banner?',
                        },
                    })
                    .afterClosed()
                    .pipe(
                        switchMap((res) => {
                            if (res.confirmed) {
                                return this.http.delete(`/api/banners/${id}`).pipe(
                                    tap(() => {
                                        this.getBannersEffect();
                                    }),
                                    catchError((err) => {
                                        this.toastr.error(err.error.message);
                                        return EMPTY;
                                    }),
                                );
                            }
                            return EMPTY;
                        }),
                    );
            }),
        ),
    );
}
